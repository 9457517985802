import Rails from "@rails/ujs";

import currentLocale from "shared/currentLocale";

export default ({ id, success, error }) => {
  Rails.ajax({
    type: "GET",
    dataType: "json",
    url: `/${currentLocale()}/frontoffice/api/bookings/${id}/total_price`,
    success,
    error,
  });
};
