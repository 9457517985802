import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    invoiceScrambledId: String,
    invoicePdfAvailable: Boolean,
    invoiceWebAvailable: Boolean,
  };

  connect() {
    if (!this.invoicePdfAvailableValue && !this.invoiceWebAvailableValue) {
      this.pollBookingInvoiceStatus(0);
    }
  }

  pollBookingInvoiceStatus(iteration) {
    if (iteration < 24) {
      fetch(`../../invoices/${this.invoiceScrambledIdValue}/poll_status`)
        .then((response) => response.json())
        .then((data) => {
          if (data.invoice_pdf_available === true) {
            window.location.href = "./success";
          } else {
            setTimeout(
              () => this.pollBookingInvoiceStatus(iteration + 1),
              5000
            );
          }
        });
    } else {
      window.location.href = "./success?web_invoice_available=true";
    }
  }
}
