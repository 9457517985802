import { Controller } from "@hotwired/stimulus";
import flightSearch from "backoffice/bookingResourceSkus/edit";
import initializeFlatpickr from "@shared/date_picker";

export default class extends Controller {
  static targets = [
    "container",
    "template",
    "flightItem",
    "carrier",
    "flightNumber",
    "date",
    "results",
    "errorMessage",
    "carrierError",
    "flightNumberError",
    "dateError",
    "searchButton",
    "loadResults",
    "loadUpdate",
  ];

  static values = {
    dateMissing: String,
    dateInvalid: String,
  };

  connect() {
    this.inactiveClasses = "text-opacity-0 pointer-events-none".split(" ");
    this.dateErrorMessage = this.dateMissingValue;
    this.dateValid = false;
  }

  waitForUpdate() {
    this.loadUpdateTarget.classList.add("pointer-events-none");
    this.loadUpdateTarget.firstElementChild.classList.add("animate-spin");
  }

  removeFlight(event) {
    this.flightItem = event.target.closest("li");

    this.flightItem.remove();

    this.flightItemTargets.forEach((flight, index) => {
      this.node = flight;
      this.index = index;
      this.regex = /\d+/.exec(flight.firstElementChild.textContent) - 1;

      this.updateNode();

      const flightItemTitle = flight.firstElementChild;
      flightItemTitle.textContent = flightItemTitle.textContent.replace(
        /\d+/,
        this.index + 1
      );
    });

    event.preventDefault();
  }

  addFlight(event) {
    this.node = this.containerTarget.insertBefore(
      this.templateTarget.content.firstElementChild.cloneNode(true),
      null
    );

    this.index = this.flightItemTargets.length - 1;
    this.regex = "FLIGHT_ID";

    this.updateNode();

    this.node.firstElementChild.textContent = `${this.flightItemTargets.length}. ${this.node.firstElementChild.textContent}`;

    initializeFlatpickr();

    event.preventDefault();
  }

  updateNode() {
    Array.from(
      this.node.querySelectorAll("label, select, input, textarea")
    ).forEach((element) => {
      const el = element;

      if (el.tagName === "LABEL") {
        const newVal = el.getAttribute("for").replace(this.regex, this.index);
        el.setAttribute("for", newVal);
      } else {
        el.id = el.id.replace(this.regex, this.index);
        el.name = el.name.replace(this.regex, this.index);
      }
    });
  }

  handleMissingFields() {
    if (!this.carrierTarget.value) {
      this.carrierTarget.classList.add("border-red-600");
      this.carrierErrorTarget.classList.remove("hidden");
    }
    if (!this.flightNumberTarget.value) {
      this.flightNumberTarget.classList.add("border-red-600");
      this.flightNumberErrorTarget.classList.remove("hidden");
    }
    if (!this.dateValid) {
      this.dateTarget.classList.add("border-red-600");
      this.dateErrorTarget.innerHTML = this.dateErrorMessage;
      this.dateErrorTarget.classList.remove("hidden");
    }
  }

  validateOnTarget() {
    if (!this.dateTarget.value) {
      this.dateErrorMessage = this.dateMissingValue;
      this.dateValid = false;
    } else {
      const today = new Date();
      const lowerLimit = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate()
      );
      const upperLimit = new Date(
        today.getFullYear(),
        today.getMonth() + 13,
        today.getDate()
      );

      const requestedDate = new Date(this.dateTarget.value);
      requestedDate.setHours(requestedDate.getHours() - 1);

      if (lowerLimit > requestedDate || requestedDate > upperLimit) {
        this.dateErrorMessage = this.dateInvalidValue;
        this.dateValid = false;
      } else {
        this.dateValid = true;
      }
    }
  }

  searchFlight() {
    this.carrierTarget.classList.remove("border-red-600");
    this.flightNumberTarget.classList.remove("border-red-600");
    this.dateTarget.classList.remove("border-red-600");
    this.errorMessageTargets.forEach((message) =>
      message.classList.add("hidden")
    );

    this.validateOnTarget();

    if (
      !(
        this.carrierTarget.value &&
        this.flightNumberTarget.value &&
        this.dateValid
      )
    ) {
      this.handleMissingFields();
      return;
    }

    this.loadResultsTarget.classList.remove("hidden");
    this.searchButtonTarget.classList.add(...this.inactiveClasses);
    this.searchButtonTarget.setAttribute("disabled", true);

    const success = (response) => {
      this.resultsTarget.style.display = "block";
      this.loadResultsTarget.classList.add("hidden");
      this.searchButtonTarget.classList.remove(...this.inactiveClasses);
      this.searchButtonTarget.setAttribute("disabled", false);

      const sendResponse = new CustomEvent("send-response", {
        detail: {
          flights: response.flights,
          airports: response.airports,
          error: response.error,
        },
      });

      window.dispatchEvent(sendResponse);
    };

    const error = () => {
      "not found";
    };

    flightSearch({
      carrier: this.carrierTarget.value.trim(),
      flightNumber: this.flightNumberTarget.value.trim(),
      onDate: this.dateTarget.value,
      success,
      error,
    });
  }

  stopSearch() {
    this.loadResultsTarget.classList.add("hidden");
    this.searchButtonTarget.classList.remove(...this.inactiveClasses);
    this.searchButtonTarget.setAttribute("disabled", false);
  }

  // changeFromTo() {
  //   const from = this.fromTarget.value;
  //   const to = this.toTarget.value;

  //   this.fromTarget.value = to;
  //   this.toTarget.value = from;
  // }
}
