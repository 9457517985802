import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "airline",
    "flightNumber",
    "departureAirport",
    "arrivalAirport",
    "departureTime",
    "arrivalTime",
    "timeSpan",
    "travelTime",
    "item",
    "emptyMessage",
  ];

  static values = {
    locale: String,
    minutes: String,
    hours: String,
  };

  localeDateTime(dateTimeString) {
    const dateTime = new Date(dateTimeString);

    const time = dateTime.toLocaleTimeString(`${this.localeValue}`, {
      hour: "2-digit",
      minute: "2-digit",
    });
    const date = dateTime.toLocaleDateString(`${this.localeValue}`);

    return `${date}, ${time}`;
  }

  timeSpan(depTime, arrTime) {
    let departure = new Date(depTime);
    let arrival = new Date(arrTime);
    const suffix = arrival.getDate() > departure.getDate() ? " (+1)" : "";

    departure = departure.toLocaleTimeString(`${this.localeValue}`, {
      hour: "2-digit",
      minute: "2-digit",
    });

    arrival = arrival.toLocaleTimeString(`${this.localeValue}`, {
      hour: "2-digit",
      minute: "2-digit",
    });

    return `${departure} - ${arrival}${suffix}`;
  }

  travelTime(depTime, arrTime, airports) {
    const timeDifference =
      airports.departure.utc_offset_hours - airports.arrival.utc_offset_hours;
    const departure = new Date(depTime);
    const arrival = new Date(arrTime);

    const totalTime = (arrival - departure) / 1000 / 60;

    const hours = parseInt(totalTime / 60, 10);
    const hoursString =
      hours + timeDifference > 0
        ? `${hours + timeDifference} ${this.hoursValue}`
        : "";

    const minutes = totalTime - 60 * hours;
    const minutesString = minutes > 0 ? ` ${minutes} ${this.minutesValue}` : "";

    return `${hoursString}${minutesString}`;
  }

  handleResults(event) {
    this.itemTargets.forEach((item, index) => {
      item.classList.remove("hidden");
      if (index !== 0) item.remove();
    });

    if (event.detail.error) {
      this.emptyMessageTarget.classList.remove("hidden");
      this.itemTarget.classList.add("hidden");
    } else {
      const { flights, airports } = event.detail;

      this.fillData(flights, airports);
    }

    event.preventDefault();
  }

  fillData(data, airports) {
    this.emptyMessageTarget.classList.add("hidden");

    const parentNode = this.itemTarget.parentElement;

    data.forEach((element, index) => {
      if (!this.itemTargets[index]) {
        parentNode.insertBefore(this.itemTarget.cloneNode(true), null);
      }
      this.airlineTargets[index].innerHTML = element.carrier_fs_code;
      this.flightNumberTargets[index].innerHTML = element.flight_number;
      this.departureAirportTargets[index].innerHTML =
        element.departure_airport_fs_code;
      this.arrivalAirportTargets[index].innerHTML =
        element.arrival_airport_fs_code;
      this.timeSpanTargets[index].innerHTML = this.timeSpan(
        element.departure_time,
        element.arrival_time
      );
      this.travelTimeTargets[index].innerHTML = this.travelTime(
        element.departure_time,
        element.arrival_time,
        airports
      );
      this.departureTimeTargets[index].innerHTML = element.departure_time;
      this.arrivalTimeTargets[index].innerHTML = element.arrival_time;
    });
  }
}
