/* eslint-disable camelcase */
import { Controller } from "@hotwired/stimulus";

import { remove } from "frontoffice/services/bookingResourceSkusService";

export default class extends Controller {
  static targets = [
    "item",
    "image",
    "name",
    "onwardJourneyStartsOn",
    "returnJourneyStartsOn",
    "onwardJourneyName",
    "returnJourneyName",
    "price",
    "removeButton",
  ];

  static values = {
    transferType: String,
    resourceSkuId: Number,
    onwardJourneyId: String,
    returnJourneyId: String,
    onwardJourneyQuantity: String,
    returnJourneyQuantity: String
  };

  initialize() {
    if (this.element.dataset.onwardJourneyBookingResourceSku) {
      this.onwardJourneyBookingResourceSku = JSON.parse(
        this.element.dataset.onwardJourneyBookingResourceSku
      );
      this.onwardJourneyIdValue = this.onwardJourneyBookingResourceSku.attributes.id;

      this.returnJourneyBookingResourceSku = JSON.parse(
        this.element.dataset.returnJourneyBookingResourceSku
      );
      this.returnJourneyIdValue = this.returnJourneyBookingResourceSku.attributes.id;

      this.transferTypeValue = this.element.dataset.transferType;
      this.resourceSkuIdValue = this.element.dataset.resourceSkuId;

      this.updateDetails();
    }
  }

  updateDetails() {
    const { id, teaser_image, name, quantity, localized_starts_on, localized_price } =
      this.onwardJourneyBookingResourceSku.attributes;

    const returnJourneyQuantity = this.returnJourneyBookingResourceSku.attributes.quantity;
    const returnJourneyPrice = this.returnJourneyBookingResourceSku.attributes.localized_price;
    const returnJourneyStartsOn = this.returnJourneyBookingResourceSku.attributes.localized_starts_on;
    
    const transferName = name.split(" - ").slice(1).join(" - ")
    this.onwardJourneyNameTarget.innerHTML = `${quantity}x ${transferName}`;
    this.returnJourneyNameTarget.innerHTML = `${returnJourneyQuantity}x ${transferName}`;
    
    this.onwardJourneyStartsOnTargets.forEach(target => target.innerHTML = localized_starts_on);
    this.returnJourneyStartsOnTargets.forEach(target => target.innerHTML = returnJourneyStartsOn);
    
    const total_price = this.addLocalizedCurrencies(localized_price, returnJourneyPrice);
    this.priceTarget.innerHTML = total_price;
    
    this.onwardJourneyQuantityValue = quantity;
    this.returnJourneyQuantityValue = returnJourneyQuantity;

    if (teaser_image) {
      this.imageTarget.src = teaser_image;
    }
  }

  updateBookingTotalPrice() {
    const bookingResourceSkusChanged = new CustomEvent(
      "booking-resource-skus-changed",
      {
        bubbles: true,
        cancelable: true,
        composed: false,
      }
    );

    this.element.dispatchEvent(bookingResourceSkusChanged);
  }

  remove(event) {
    const error = () => { };
    const success = () => {
      // remove return journey sku as well
      remove({
        id: this.returnJourneyIdValue,
        success: () => {
          this.updateBookingTotalPrice();
          this.itemTarget.remove();

          const bookingResourceSkuRemoved = new CustomEvent("item-removed", {
            detail: {
              resourceSkuId: this.resourceSkuIdValue,
              transferType: this.transferTypeValue,
              quantity: parseInt(this.onwardJourneyQuantityValue),
            }
          });

          window.dispatchEvent(bookingResourceSkuRemoved);
        },
        error
      });
    };

    // remove onward journey sku
    remove({
      id: this.onwardJourneyIdValue,
      success,
      error,
    });
  }

  addLocalizedCurrencies(value1, value2) {
    let totalVal = this.parseLocalizedCurrency(value1) + this.parseLocalizedCurrency(value2);
    let prefix = this.getCurrencyPrefix(value1);
    return this.formatCurrency(prefix, totalVal);
  }

  parseLocalizedCurrency(val) {
    // Trim any leading/trailing whitespace
    const trimmed = val.trim();

    // Find the first digit in the string
    const firstDigitIndex = trimmed.search(/\d/);
    
    // Get the main part
    const mainPart = trimmed.slice(firstDigitIndex);

    // Parse the numeric value
    return parseFloat(mainPart.replace(',', '.'));
  }

  getCurrencyPrefix(val) {
    // Trim any leading/trailing whitespace
    const trimmed = val.trim();

    // Find the first digit in the string
    const firstDigitIndex = trimmed.search(/\d/);

    // Get the prefix
    return trimmed.slice(0, firstDigitIndex).trim();
  }

  formatCurrency(prefix, val) {
    // Format the value
    const formattedValue = val.toFixed(2).replace('.', ',');

    // Reconstruct the string
    return `${prefix}${formattedValue}`;
  }
}
