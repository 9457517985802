import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["bookedTransferTemplate", "bookedTransfersSection"];

  updateBookedPositions(event) {
    const node =
      this.bookedTransferTemplateTarget.content.firstElementChild.cloneNode(
        true
      );

    node.dataset.onwardJourneyBookingResourceSku = JSON.stringify(event.detail.onwardJourneyData);
    node.dataset.returnJourneyBookingResourceSku = JSON.stringify(event.detail.returnJourneyData);
    node.dataset.transferType = event.detail.transferType;
    node.dataset.resourceSkuId = event.detail.resourceSkuId;

    this.bookedTransfersSectionTarget.appendChild(node);

    if (node.scrollIntoView) {
      this.element.scrollIntoView({
        behavior: "smooth",
      });
    } else {
      window.scrollTo(node);
    }
  }
}
