export default (bookingRoomAssignmentId) => {
  const event = new CustomEvent("booking-room-assignment-chosen", {
    detail: {
      focusedAssignmentId: bookingRoomAssignmentId,
    },
    bubbles: true,
    cancelable: true,
    composed: false,
  });

  window.dispatchEvent(event);
};
