import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["flightItem", "form", "modal", "submitButton"];

  validate(event) {
    event.preventDefault();
    this.submitButtonTarget.setAttribute("disabled", "true");
    let valid = true;

    this.flightItemTargets.forEach((flightItem) => {
      const departureAt = new Date(
        flightItem.querySelector("[data-target='departureAt']").value
      );
      const arrivalAt = new Date(
        flightItem.querySelector("[data-target='arrivalAt']").value
      );

      if (arrivalAt < departureAt) {
        valid = false;
      }
    });

    if (!valid) {
      this.showModal();
    } else {
      this.formTarget.submit();
    }
    setTimeout(() => this.submitButtonTarget.removeAttribute("disabled"), 2000);
  }

  showModal() {
    this.modalTarget.style.display = "block";
  }

  confirm() {
    this.modalTarget.style.display = "none";
    this.formTarget.submit();
  }

  cancel(event) {
    event.preventDefault();
    this.modalTarget.style.display = "none";
  }
}
