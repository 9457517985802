import { Controller } from "@hotwired/stimulus";
import bookingRoomAssignmentChosen from "frontoffice/services/bookingRoomAssignments";

export default class AccommodationRequestController extends Controller {
  static targets = [
    "bookingRoomAssignment",
    "requestedRoomsSection",
    "nextButton",
  ];

  static classes = ["disabledButton"];

  static values = {
    focusedAssignmentId: Number,
    roomMissingInfo: Array,
  };

  connect() {
    const roomsMissing = this.roomMissingInfoValue.some(
      (el) => el.room_missing === true
    );

    if (roomsMissing) {
      this.nextButtonTarget.classList.add(...this.disabledButtonClasses);
    } else {
      this.nextButtonTarget.classList.remove(...this.disabledButtonClasses);
    }

    setTimeout(
      () => bookingRoomAssignmentChosen(this.focusedAssignmentIdValue),
      10
    );
  }

  switchToNextTab(response) {
    const { focusedAssignmentId } = response.detail;

    const nextBookingRoomAssignment =
      this.findNextBookingRoomAssignment(focusedAssignmentId);

    if (nextBookingRoomAssignment) {
      bookingRoomAssignmentChosen(nextBookingRoomAssignment.id);
      this.requestedRoomsSectionTarget.scrollIntoView({
        behavior: "smooth",
      });
    } else {
      this.nextButtonTarget.classList.remove(...this.disabledButtonClasses);
      this.nextButtonTarget.scrollIntoView({
        behavior: "smooth",
      });
    }
  }

  updatedMissingInfo(focusedIndex) {
    const newInfo = this.roomMissingInfoValue;

    newInfo[focusedIndex].room_missing = false;

    return newInfo;
  }

  findNextBookingRoomAssignment(focusedAssignmentId) {
    const focusedIndex = this.roomMissingInfoValue.findIndex(
      (info) => info.id === focusedAssignmentId
    );

    this.roomMissingInfoValue = this.updatedMissingInfo(focusedIndex);

    return this.roomMissingInfoValue.find((info) => info.room_missing === true);
  }
}
