import { Controller } from "@hotwired/stimulus";
import updateApproval from "backoffice/flights";

export default class extends Controller {
  static targets = ["modal", "message"];

  static values = {
    flight: Object,
    lastVersionId: Number,
    currentUserId: Number,
    approvableId: Number,
    approvableType: String,
  };

  handleChange(event) {
    event.preventDefault();

    const { id } = this.flightValue;
    const lastVersionId = this.lastVersionIdValue;
    const currentUserId = this.currentUserIdValue;
    const approvableId = this.approvableIdValue;
    const approvableType = this.approvableTypeValue;
    let approved = false;

    if (event.target.checked) approved = true;

    const success = () => {
      if (event.target.checked && this.hasMessageTarget)
        this.messageTarget.remove();
    };

    const error = () => {};

    updateApproval({
      id,
      lastVersionId,
      currentUserId,
      approvableId,
      approvableType,
      approved,
      success,
      error,
    });
  }

  showDetails() {
    this.modalTarget.style.display = "block";
  }
}
