import { Controller } from "@hotwired/stimulus";
import bookingRoomAssignmentChosen from "frontoffice/services/bookingRoomAssignments";

export default class BookingRoomAssignmentController extends Controller {
  static targets = ["bookingRoomAssignment", "bookingRoomAssignmentText"];

  static classes = ["activeBookingRoomAssignment"];

  static values = {
    bookingRoomAssignmentId: Number,
  };

  select(event) {
    event.preventDefault();

    if (
      event.detail.focusedAssignmentId === this.bookingRoomAssignmentIdValue
    ) {
      this.bookingRoomAssignmentTarget.classList.add(
        ...this.activeBookingRoomAssignmentClasses
      );
    } else {
      this.bookingRoomAssignmentTarget.classList.remove(
        ...this.activeBookingRoomAssignmentClasses
      );
    }
  }

  selectTab(e) {
    e.preventDefault();

    bookingRoomAssignmentChosen(this.bookingRoomAssignmentIdValue);
  }

  replaceText(event) {
    const { focusedAssignmentId, response } = event.detail;

    if (focusedAssignmentId !== this.bookingRoomAssignmentIdValue) {
      return;
    }

    const { attributes } = response.data;

    this.bookingRoomAssignmentTextTarget.innerHTML = attributes.summary_text;

    this.updateBookingTotalPrice();
  }

  updateBookingTotalPrice() {
    const bookingResourceSkusChanged = new CustomEvent(
      "booking-resource-skus-changed",
      {
        bubbles: true,
        cancelable: true,
        composed: false,
      }
    );

    this.element.dispatchEvent(bookingResourceSkusChanged);
  }
}
