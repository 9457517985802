import { Controller } from "@hotwired/stimulus";

export default class FormValidationController extends Controller {
  static targets = [
    "firstName",
    "lastName",
    "gender",
    "address",
    "zip",
    "city",
    "country",
    "locale",
    "birthdate",
    "email",
    "phone",
  ];

  initialize() {
    this.validateFirstName();
    this.validateLastName();
    this.validateGender();
    this.validateAddress();
    this.validateZipCity();
    this.validateCountry();
    this.validateLocale();
    this.validateBirthdate();
    this.validateEmail();
    this.validatePhone();
  }

  static createCustomEvent(eventName, isValid, element) {
    const event = new CustomEvent(eventName, {
      detail: {
        isValid,
        element,
      },
    });

    return event;
  }

  static validateInput(value) {
    if (value !== "") {
      return true;
    }

    return false;
  }

  static validateRegex(value, regex) {
    if (regex.test(value)) {
      return true;
    }

    return false;
  }

  validateFirstName() {
    if (this.hasFirstNameTarget) {
      setTimeout(() => {
        const isValid = FormValidationController.validateInput(
          this.firstNameTarget.value
        );

        const firstNameValidEvent = FormValidationController.createCustomEvent(
          "first-name-valid",
          isValid,
          this.firstNameTarget
        );

        window.dispatchEvent(firstNameValidEvent);
      }, 100);
    }
  }

  validateLastName() {
    if (this.hasLastNameTarget) {
      setTimeout(() => {
        const isValid = FormValidationController.validateInput(
          this.lastNameTarget.value
        );

        const lastNameValidEvent = FormValidationController.createCustomEvent(
          "last-name-valid",
          isValid,
          this.lastNameTarget
        );

        window.dispatchEvent(lastNameValidEvent);
      }, 100);
    }
  }

  validateGender() {
    if (this.hasGenderTarget) {
      setTimeout(() => {
        const isValid = FormValidationController.validateInput(
          this.genderTarget.value
        );

        const genderValidEvent = FormValidationController.createCustomEvent(
          "gender-valid",
          isValid,
          this.genderTarget
        );

        window.dispatchEvent(genderValidEvent);
      }, 100);
    }
  }

  validateAddress() {
    if (this.hasAddressTarget) {
      setTimeout(() => {
        const isValid = FormValidationController.validateInput(
          this.addressTarget.value
        );

        const addressValidEvent = FormValidationController.createCustomEvent(
          "address-valid",
          isValid,
          this.addressTarget
        );

        window.dispatchEvent(addressValidEvent);
      }, 100);
    }
  }

  validateZipCity() {
    if (this.hasZipTarget && this.hasCityTarget) {
      setTimeout(() => {
        const isValid =
          this.zipTarget.value !== "" && this.cityTarget.value !== "";

        const zipCityValidEvent = FormValidationController.createCustomEvent(
          "zip-city-valid",
          isValid,
          this.cityTarget.parentElement
        );

        window.dispatchEvent(zipCityValidEvent);
      }, 100);
    }
  }

  validateCountry() {
    if (this.hasCountryTarget) {
      setTimeout(() => {
        const isValid = FormValidationController.validateInput(
          this.countryTarget.value
        );

        const countryValidEvent = FormValidationController.createCustomEvent(
          "country-valid",
          isValid,
          this.countryTarget
        );

        window.dispatchEvent(countryValidEvent);
      }, 100);
    }
  }

  validateLocale() {
    if (this.hasLocaleTarget) {
      setTimeout(() => {
        const isValid = FormValidationController.validateInput(
          this.localeTarget.value
        );

        const localeValidEvent = FormValidationController.createCustomEvent(
          "locale-valid",
          isValid,
          this.localeTarget
        );

        window.dispatchEvent(localeValidEvent);
      }, 100);
    }
  }

  validateBirthdate() {
    if (this.hasBirthdateTarget) {
      const today = new Date();
      const birthdate = new Date(this.birthdateTarget.value);
      let isValid = false;

      setTimeout(() => {
        if (this.birthdateTarget.value !== "" && birthdate < today) {
          isValid = true;
        } else {
          isValid = false;
        }

        const birthdateValidEvent = FormValidationController.createCustomEvent(
          "birthdate-valid",
          isValid,
          this.birthdateTarget
        );

        window.dispatchEvent(birthdateValidEvent);
      }, 100);
    }
  }

  validateEmail() {
    if (this.hasEmailTarget) {
      const validEmailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

      setTimeout(() => {
        const isValid = FormValidationController.validateRegex(
          this.emailTarget.value,
          validEmailRegex
        );

        const emailValidEvent = FormValidationController.createCustomEvent(
          "email-valid",
          isValid,
          this.emailTarget
        );

        window.dispatchEvent(emailValidEvent);
      }, 100);
    }
  }

  validatePhone() {
    if (this.hasPhoneTarget) {
      setTimeout(() => {
        const isValid = FormValidationController.validateInput(
          this.phoneTarget.value
        );

        const phoneValidEvent = FormValidationController.createCustomEvent(
          "phone-valid",
          isValid,
          this.phoneTarget
        );

        window.dispatchEvent(phoneValidEvent);
      }, 100);
    }
  }
}
