import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "background", "editable"];

  openModal(event) {
    event.preventDefault();

    this.modalTarget.style.display = "block";
    if (this.hasEditableTarget) this.editableTarget.select();
  }

  closeModal(event) {
    event.preventDefault();

    this.modalTarget.style.display = "none";
  }
}
