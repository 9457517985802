import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "list",
    "sortBy",
    "sortOrder",
    "show",
    "checkmark",
    "li",
    "dropdown",
  ];

  toggle(event) {
    event.preventDefault();

    if (this.listTarget.getAttribute("aria-expanded") === "false") {
      this.show();
    } else {
      this.hide();
    }
  }

  handleClick(event) {
    if (event.target.closest("div") === this.dropdownTarget) {
      this.toggle(event);
    } else if (event.target !== this.showTarget) {
      this.hide();
    }
  }

  show() {
    this.listTarget.classList.remove("hidden");
    this.listTarget.setAttribute("aria-expanded", "true");
  }

  hide() {
    this.listTarget.classList.add("hidden");
    this.listTarget.setAttribute("aria-expanded", "false");
  }

  saveOption(event) {
    this.selectedLi = event.target.closest("li");
    this.sortBy = this.selectedLi.dataset.sortBy;
    this.sortOrder = this.selectedLi.dataset.sortOrder;
    this.sortByTarget.value = this.sortBy;
    this.sortOrderTarget.value = this.sortOrder;
    this.showTarget.value = this.selectedLi.dataset.name;
    this.showTarget.innerHTML = this.selectedLi.dataset.label;
    this.liTargets.forEach((item) => item.classList.remove("font-semibold"));
    this.selectedLi.classList.add("font-semibold");

    [this.showCheckmark] = this.checkmarkTargets.filter(
      (item) => item.dataset.name === this.showTarget.value
    );
    this.showCheckmark.classList.remove("hidden");

    this.hideCheckmarks = this.checkmarkTargets.filter(
      (item) => item.dataset.name !== this.showTarget.value
    );
    this.hideCheckmarks.forEach((item) => item.classList.add("hidden"));

    this.listTarget.classList.add("hidden");
  }
}
