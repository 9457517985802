import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"];

  showDetails() {
    this.modalTarget.style.display = "block";
  }

  hideDetails() {
    this.modalTarget.style.display = "none";
  }
}
