import { Controller } from "@hotwired/stimulus";

export default class TabsController extends Controller {
  static targets = ["tab", "panel", "dropdown"];

  connect() {
    this.activeTabClasses = "border-tourrise-blue text-tourrise-blue".split(
      " "
    );
    this.inactiveTabClasses =
      "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300".split(
        " "
      );
    if (TabsController.anchor) this.index = TabsController.anchor;
    this.showTab();
  }

  change(event) {
    event.preventDefault();

    if (event.currentTarget.id) {
      this.index = event.currentTarget.id;
    } else if (
      event.currentTarget.options &&
      event.currentTarget.selectedIndex !== "undefined"
    ) {
      this.index =
        event.currentTarget.options[event.currentTarget.selectedIndex].id;
    }
  }

  showTab() {
    this.tabTargets.forEach((tab) => {
      const panel = this.panelTargets.find(
        (panelTarget) => panelTarget.id === tab.id
      );
      const dropdownOption = this.dropdownTargets.find(
        (option) => option.id === tab.id
      );

      if (tab.id === this.index) {
        panel.classList.remove("hidden");
        tab.classList.remove(...this.inactiveTabClasses);
        tab.classList.add(...this.activeTabClasses);

        dropdownOption.parentElement.selectedIndex =
          this.tabTargets.indexOf(tab);

        // Update URL with the tab ID if it has one
        // This will be automatically selected on page load
        if (tab.id) {
          window.location.hash = tab.id;
        }
      } else {
        panel.classList.add("hidden");
        tab.classList.remove(...this.activeTabClasses);
        tab.classList.add(...this.inactiveTabClasses);
      }
    });
  }

  get index() {
    return this.data.get("index") || this.tabTargets[0].id;
  }

  set index(value) {
    this.data.set("index", value);
    this.showTab();
  }

  static get anchor() {
    return document.URL.split("#").length > 1
      ? document.URL.split("#")[1]
      : null;
  }
}
