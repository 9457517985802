import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "firstName",
    "lastName",
    "email",
    "birthdate",
    "participantType",
    "gender",
    "input",
    "id",
  ];

  static values = {
    validationMessage: String,
  };

  connect() {
    if (this.hasInputTarget) {
      this.inputTarget.value = "";
      this.inputTarget.setCustomValidity(this.validationMessageValue);
    }
  }

  handleChange() {
    this.fetchCustomer(this.idTarget.value);
  }

  handleResponse(data) {
    this.firstNameTarget.value = data.attributes.first_name;
    this.lastNameTarget.value = data.attributes.last_name;
    this.emailTarget.value = data.attributes.email;
    this.birthdateTarget.value = data.attributes.birthdate;
    this.participantTypeTarget.value = data.attributes.participant_type;
    this.genderTarget.value = data.attributes.gender;
  }

  fetchCustomer(id) {
    fetch(`/api/customers/${id}.json`, {
      method: "GET",
      headers: {
        ContentType: "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((results) => this.handleResponse(results.data))
      .catch((error) => {
        window.Honeybadger.notify("Cannot fetch customer", error);
      });
  }

  showCustomer() {
    window.location = `/customers/${this.idTarget.value}`;
  }

  inputInvalid() {
    if (this.inputTarget)
      this.inputTarget.setCustomValidity(this.validationMessageValue);
  }

  inputValid() {
    if (this.inputTarget) this.inputTarget.setCustomValidity("");
  }
}
