import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["editDate", "dateField"];

  editDueOn(event) {
    this.editDateTarget.classList.add("hidden");
    this.dateFieldTarget.classList.remove("hidden");

    event.preventDefault();
  }

  saveDueOn() {
    setTimeout(() => {
      this.dateFieldTarget.classList.add("hidden");
      this.editDateTarget.classList.remove("hidden");
    }, 500);
  }

  closeDueOn(event) {
    this.editDateTarget.classList.remove("hidden");
    this.dateFieldTarget.classList.add("hidden");

    event.preventDefault();
  }
}
