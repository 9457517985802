import Rails from "@rails/ujs";

import currentLocale from "shared/currentLocale";

export default ({ id, startsOn, endsOn, quantity, success, error }) => {
  const params = `starts_on=${startsOn}&ends_on=${endsOn}&quantity=${quantity}`;

  Rails.ajax({
    type: "GET",
    dataType: "json",
    url: `/${currentLocale()}/frontoffice/api/resource_skus/${id}/calculate_price?${params}`,
    success,
    error,
  });
};
