import Rails from "@rails/ujs";

import currentLocale from "shared/currentLocale";

export default ({ id, data, success, error }) => {
  Rails.ajax({
    type: "PUT",
    dataType: "json",
    url: `/${currentLocale()}/frontoffice/api/booking_resource_skus/${id}`,
    beforeSend: (xhr, options) => {
      // Workaround: add options.data late to avoid Content-Type header to already being set in stone
      // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
      xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
      // eslint-disable-next-line no-param-reassign
      options.data = JSON.stringify(data);
      return true;
    },
    success,
    error,
  });
};
