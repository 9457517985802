import { Controller } from "@hotwired/stimulus";

import { fetchTotalPrice } from "frontoffice/services/bookingService";

export default class extends Controller {
  static targets = ["totalPrice"];

  static values = {
    bookingId: Number,
  };

  updatePrice() {
    const success = (response) => {
      this.totalPriceTarget.innerHTML = response;
    };
    const error = () => {};

    fetchTotalPrice({
      id: this.bookingIdValue,
      success,
      error,
    });
  }
}
