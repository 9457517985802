import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["wrapper", "gallery", "nextButton", "prevButton"];

  static values = {
    index: Number,
    images: Number,
  };

  connect() {
    this.handleButtons();
    this.timeout = 500;
  }

  indexValueChanged() {
    this.galleryTarget.style = `--tw-translate-x: -${
      this.indexValue * this.galleryTarget.offsetWidth
    }px;`;
    this.controlImageHeight();

    this.handleButtons();
  }

  controlImageHeight() {
    if (this.indexValue >= 0) {
      const height =
        window.screen.height > 500
          ? this.galleryTarget.children[this.indexValue].offsetHeight
          : 350;
      setTimeout(() => {
        this.galleryTarget.parentElement.style.height = `${height}px`;
      }, this.timeout);

      this.timeout = 0;
    }
  }

  handleButtons() {
    if (this.imagesValue > 1) {
      if (this.indexValue === 0) {
        this.prevButtonTarget.disabled = true;
        this.nextButtonTarget.disabled = false;
      } else if (this.indexValue >= this.imagesValue - 1) {
        this.nextButtonTarget.disabled = true;
        this.prevButtonTarget.disabled = false;
      } else {
        this.nextButtonTarget.disabled = false;
        this.prevButtonTarget.disabled = false;
      }
    }
  }

  showGallery(event) {
    this.indexValue = event.target.dataset.index;
    this.wrapperTarget.classList.remove("hidden");

    event.preventDefault();
  }

  hideGallery(event) {
    this.wrapperTarget.classList.add("hidden");

    event.preventDefault();
  }

  next(event) {
    this.prevButtonTarget.disabled = false;
    this.indexValue += 1;

    if (this.indexValue >= this.imagesValue - 1)
      this.nextButtonTarget.disabled = true;

    event.preventDefault();
  }

  previous(event) {
    this.nextButtonTarget.disabled = false;
    this.indexValue -= 1;

    if (this.indexValue === 0) this.prevButtonTarget.disabled = true;

    event.preventDefault();
  }
}
