import { Controller } from "@hotwired/stimulus";

export default class RequestStepController extends Controller {
  static targets = ["container", "template", "roomItem"];

  removeRoom(event) {
    this.roomItem = event.target.closest("li");

    this.roomItem.remove();

    this.roomItemTargets.forEach((room, index) => {
      RequestStepController.updateRoom(room, index, /\d+/);
    });

    event.preventDefault();
  }

  addRoom(event) {
    const newNode = this.containerTarget.insertBefore(
      this.templateTarget.content.firstElementChild.cloneNode(true),
      null
    );

    const index = this.roomItemTargets.length - 1;

    RequestStepController.updateRoom(newNode, index, "ROOM_ID");

    event.preventDefault();
  }

  static updateRoom(targetNode, index, regex) {
    const node = targetNode;
    Array.from(node.querySelectorAll("label, select, input")).forEach(
      (element) => {
        const el = element;

        if (el.tagName === "LABEL") {
          const newVal = el.getAttribute("for").replace(regex, index);
          el.setAttribute("for", newVal);
        } else {
          el.id = el.id.replace(regex, index);
          el.name = el.name.replace(regex, index);
        }
      }
    );

    const newTitle = node.firstElementChild.textContent.replace(
      /\d+/,
      index + 1
    );
    node.firstElementChild.textContent = newTitle;
  }
}
