import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["check", "spinner"];

  static values = { isValid: Boolean };

  connect() {
    this.displayClass = "hidden";
  }

  currentCheckMark(el) {
    const inNodeList =
      Array.from(el.parentElement.parentElement.children).indexOf(
        this.checkTarget
      ) > -1;

    return inNodeList;
  }

  isValidValueChanged() {
    this.spinnerTarget.classList.remove(this.displayClass);
    this.checkTarget.classList.add(this.displayClass);

    setTimeout(() => {
      this.spinnerTarget.classList.add(this.displayClass);

      if (this.isValidValue) {
        this.checkTarget.classList.remove(this.displayClass);
      } else {
        this.checkTarget.classList.add(this.displayClass);
      }
    }, 400);
  }

  isValid(event) {
    if (this.currentCheckMark(event.detail.element)) {
      if (event.detail.isValid) {
        this.isValidValue = true;
      } else {
        this.isValidValue = false;
      }
    }
  }
}
