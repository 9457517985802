import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["option", "rentalbike"];

  static classes = ["activeOption", "hidden"];

  initialize() {
    this.reset();
  }

  show(event) {
    const { id } = event.currentTarget.dataset;

    this.setActive(id);
    this.showDetails(id);
  }

  setActive(id) {
    this.optionTargets.forEach((element) => {
      if (element.dataset.id === id) {
        element.classList.add(this.activeOptionClass);
      } else {
        element.classList.remove(this.activeOptionClass);
      }
    });
  }

  showDetails(id) {
    this.rentalbikeTargets.forEach((element) => {
      if (element.dataset.id === id) {
        element.classList.remove(this.hiddenClass);
      } else {
        element.classList.add(this.hiddenClass);
      }
    });
  }

  reset() {
    const { id } = this.optionTarget.dataset;

    this.setActive(id);
    this.showDetails(id);
  }
}
