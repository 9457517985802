import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["day", "month", "year", "fullDate"];

  connect() {
    this.day = "";
    this.month = "";
    this.year = "";
    this.min = 1;
    this.maxDay = 31;
    this.maxMonth = 12;
  }

  inRange(value, max) {
    return value >= this.min && value <= max;
  }

  saveDay() {
    this.saveFullDate();

    if (this.dayTarget.value.length === 2) {
      this.monthTarget.focus();
    }
  }

  saveMonth() {
    this.saveFullDate();

    if (this.monthTarget.value.length === 2) {
      this.yearTarget.focus();
    }
  }

  saveFullDate() {
    if (
      this.inRange(this.dayTarget.value, this.maxDay) &&
      this.inRange(this.monthTarget.value, this.maxMonth) &&
      this.yearTarget.value.length === 4
    ) {
      this.day = this.dayTarget.value;
      this.month = this.monthTarget.value;
      this.year = this.yearTarget.value;
      this.fullDateTarget.value = new Date(this.year, this.month - 1, this.day);
    } else {
      this.fullDateTarget.value = "";
    }
  }
}
