import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "container",
    "interval",
    "template",
    "globalLevel",
    "productLevel",
    "products",
  ];

  connect() {
    this.toggleProducts();
  }

  addInterval() {
    const newNode = this.containerTarget.insertBefore(
      this.templateTarget.content.firstElementChild.cloneNode(true),
      null
    );

    const newIndex = this.intervalTargets.length - 1;

    Array.from(newNode.querySelectorAll("label, select, input")).forEach(
      (element) => {
        const el = element;

        if (el.tagName === "LABEL") {
          const newVal = el
            .getAttribute("for")
            .replace("_ID_", `_${newIndex}_`);
          el.setAttribute("for", newVal);
        } else {
          el.id = el.id.replace("_ID_", `_${newIndex}_`);
          el.name = el.name.replace("[ID]", `[${newIndex}]`);
        }
      }
    );
  }

  removeInterval(event) {
    this.intervalItem = event.target.closest("li");

    this.intervalItem.remove();
  }

  toggleProducts() {
    if (this.globalLevelTarget.checked) {
      this.productsTarget.classList.add("hidden");
    } else {
      this.productsTarget.classList.remove("hidden");
    }
  }
}
