import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tooltip", "triangle"];

  connect() {
    this.top_classes = "-translate-y-full -top-1.5".split(" ");
    this.bottom_classes = "translate-y-full -bottom-1.5".split(" ");
    this.shift_right = ["-left-0.5"];
    this.shift_left = ["-right-0.5"];
    this.full_right = "translate-x-full -right-1.5".split(" ");
    this.full_left = "-translate-x-full -left-1.5".split(" ");
    this.center_x = "-translate-x-1/2 left-1/2".split(" ");
    this.center_y = "-translate-y-1/2 top-1/2".split(" ");

    this.triangle_up =
      "rotate-0 -translate-x-1/2 left-1/2 -translate-y-1/2 -top-px".split(" ");
    this.triangle_down =
      "rotate-180 -translate-x-1/2 left-1/2 translate-y-1/2 -bottom-px".split(
        " "
      );
    this.triangle_right = "rotate-90 translate-x-1/2 -right-px".split(" ");
    this.triangle_left = "-rotate-90 -translate-x-1/2 -left-px".split(" ");

    this.positionTooltip();
  }

  clearClasses() {
    this.tooltipTarget.classList.remove(
      ...this.top_classes,
      ...this.bottom_classes,
      ...this.shift_right,
      ...this.shift_left,
      ...this.full_right,
      ...this.full_left,
      ...this.center_x,
      ...this.center_y
    );

    this.triangleTarget.classList.remove(
      ...this.triangle_up,
      ...this.triangle_down,
      ...this.triangle_right,
      ...this.triangle_left
    );
  }

  positionTooltip() {
    this.clearClasses();
    const elem = this.tooltipTarget.getBoundingClientRect();

    if (elem.top < elem.height) {
      this.tooltipTarget.classList.add(...this.bottom_classes);
      this.triangleTarget.classList.add(...this.triangle_down);
      if (elem.left + elem.width / 1.5 > window.innerWidth) {
        this.tooltipTarget.classList.add(...this.shift_left);
      } else if (elem.left < elem.width / 2) {
        this.tooltipTarget.classList.add(...this.shift_right);
      } else {
        this.tooltipTarget.classList.add(...this.center_x);
      }
    } else if (elem.bottom > window.innerHeight) {
      this.tooltipTarget.classList.add(...this.top_classes);
      this.triangleTarget.classList.add(...this.triangle_up);
      if (elem.left + elem.width / 1.5 > window.innerWidth) {
        this.tooltipTarget.classList.add(...this.shift_left);
      } else if (elem.left < elem.width / 2) {
        this.tooltipTarget.classList.add(...this.shift_right);
      } else {
        this.tooltipTarget.classList.add(...this.center_x);
      }
    } else if (elem.left + elem.width / 1.5 > window.innerWidth) {
      this.triangleTarget.classList.add(...this.triangle_left);
      this.tooltipTarget.classList.add(...this.full_left);
    } else if (elem.left < elem.width / 2) {
      this.triangleTarget.classList.add(...this.triangle_right);
      this.tooltipTarget.classList.add(...this.full_right);
    } else {
      this.tooltipTarget.classList.add(...this.top_classes);
      this.triangleTarget.classList.add(...this.triangle_up);
      this.tooltipTarget.classList.add(...this.center_x);
    }
  }
}
