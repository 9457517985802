import Rails from "@rails/ujs";

import currentLocale from "shared/currentLocale";

export default ({ id, success, error }) => {
  Rails.ajax({
    type: "DELETE",
    dataType: "json",
    url: `/${currentLocale()}/frontoffice/api/booking_resource_skus/${id}`,
    success,
    error,
  });
};
