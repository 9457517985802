import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["editItem", "editField", "content", "tr", "actions"];

  static values = {
    newlyAdded: Boolean,
  };

  connect() {
    if (this.newlyAddedValue) {
      this.activateEditField();
    }
  }

  activateEditField() {
    this.editItemTargets.forEach((target) => {
      target.classList.add("hidden");
    });
    this.editFieldTarget.classList.remove("hidden");
    if (this.hasContentTarget) this.contentTarget.select();
  }

  editItem(event) {
    if (this.hasEditItemTarget && this.hasEditFieldTarget) {
      this.actionsTarget.classList.add("hidden");
      this.activateEditField();

      if (event) event.preventDefault();
    }
  }

  saveItem() {
    setTimeout(() => {
      this.editFieldTarget.classList.add("hidden");
      this.actionsTarget.classList.remove("hidden");
      this.editItemTargets.forEach((target) => {
        target.classList.remove("hidden");
      });
    }, 500);
  }

  abortEdit(event) {
    this.editItemTargets.forEach((target) => {
      target.classList.remove("hidden");
    });
    this.actionsTarget.classList.remove("hidden");
    this.editFieldTarget.classList.add("hidden");

    event.preventDefault();
  }
}
