import { Controller } from "@hotwired/stimulus";

export default class AccordionController extends Controller {
  static targets = ["collapsible", "collapsibleIcon", "wrapper", "buttonText"];

  static classes = ["rotate"];

  static values = {
    showMore: String,
    showLess: String,
  };

  collapse() {
    this.collapsibleIconTarget.classList.toggle(this.rotateClass);

    if (this.collapsibleIconTarget.classList.contains(this.rotateClass)) {
      this.buttonTextTarget.innerHTML = this.showLessValue;
    } else {
      this.buttonTextTarget.innerHTML = this.showMoreValue;
    }

    AccordionController.growDiv.bind(this)();
  }

  static growDiv() {
    const growDiv = this.collapsibleTarget;

    if (growDiv.clientHeight) {
      AccordionController.toggleOverflow(growDiv);

      this.collapsibleTarget.classList.add("h-0");
      this.collapsibleTarget.classList.remove("h-full");
    } else {
      setTimeout(() => AccordionController.toggleOverflow(growDiv), 400);

      this.collapsibleTarget.classList.remove("h-0");
      this.collapsibleTarget.classList.add("h-full");
    }
  }

  static toggleOverflow(growDiv) {
    growDiv.classList.toggle("overflow-hidden");
  }
}
