import { Controller } from "@hotwired/stimulus";
import { update as updatePosition } from "frontoffice/services/bookingResourceSkusService";

export default class extends Controller {
  static targets = ["alert"];

  static values = {
    personTransferParticipants: Array,
    groupedTransferIds: Object,
  };

  checkParticipants(event) {
    if (event.detail.resourceSkuHandle.startsWith("transfer-person")) {
      if (this.personTransferParticipantsValue.includes(event.detail.name)) {
        this.alertTarget.classList.remove("hidden");
      } else {
        this.alertTarget.classList.add("hidden");
      }
      this.personTransferParticipantsValue = [
        ...this.personTransferParticipantsValue,
        event.detail.name,
      ];
    }
  }

  closeAlert(event) {
    this.alertTarget.classList.add("hidden");

    event.preventDefault();
  }

  removeParticipant(event) {
    if (event.detail.resourceSkuHandle.startsWith("transfer-person")) {
      const index = this.personTransferParticipantsValue.indexOf(
        event.detail.name
      );
      const arr = this.personTransferParticipantsValue;

      if (index !== -1) arr.splice(index, 1);

      this.personTransferParticipantsValue = arr;
    }
  }

  handleParticipantAssignment(event) {
    const onwardTransferId = event.detail.bookingResourceSkuId;
    const { selectedParticipantsIds } = event.detail;

    const returnTransferId = this.groupedTransferIdsValue[onwardTransferId];
    if (!returnTransferId) {
      // if transfer id does not exit, it means its for return transfer
      // event for return transfer is raised by this controller only, so no need to handle it
      return;
    }

    const success = () => {
      // raise the event so that final_submit_controller can handle and update its internal data
      this.element.dispatchEvent(
        new CustomEvent("selectedParticipantsIds", {
          detail: {
            bookingResourceSkuId: returnTransferId,
            selectedParticipantsIds,
          },
          bubbles: true,
        })
      );
    };
    const error = () => {};

    const data = {
      booking_resource_sku: {
        participant_ids: selectedParticipantsIds,
      },
    };

    updatePosition({
      id: returnTransferId,
      data,
      success,
      error,
    });
  }
}
