import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submenu", "chevron"];

  static values = {
    submenuName: String,
    submenuNameAlternatives: Array,
    currentPath: String,
  };

  connect() {
    if (this.hasSubmenuTarget) {
      if (
        this.currentPathValue.includes(this.submenuNameValue) ||
        this.submenuNameAlternativesValue.includes(this.currentPathValue)
      )
        this.expandSubmenu();
    }
  }

  toggleSubmenu(event) {
    event.preventDefault();

    if (this.submenuTarget.getAttribute("aria-expanded") === "false") {
      this.expandSubmenu();
    } else {
      this.submenuTarget.classList.add("hidden");
      this.chevronTarget.classList.remove("rotate-90");
      this.submenuTarget.setAttribute("aria-expanded", "false");
    }
  }

  expandSubmenu() {
    this.submenuTarget.classList.remove("hidden");
    this.chevronTarget.classList.add("rotate-90");
    this.submenuTarget.setAttribute("aria-expanded", "true");
  }
}
