import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "airline",
    "flightNumber",
    "departureAirport",
    "arrivalAirport",
    "departureTime",
    "arrivalTime",
  ];

  static values = {
    locale: String,
  };

  selectFlight(event) {
    const sendFlightData = new CustomEvent("new-flight", {
      detail: {
        airlineCode: this.airlineTarget.innerText,
        flightNumber: this.flightNumberTarget.innerText,
        takeoffAt: this.departureTimeTarget.innerText,
        takeoffAirportCode: this.departureAirportTarget.innerText,
        landingAt: this.arrivalTimeTarget.innerText,
        landingAirportCode: this.arrivalAirportTarget.innerText,
      },
    });

    window.dispatchEvent(sendFlightData);

    event.preventDefault();
  }
}
