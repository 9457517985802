import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["availability", "eventItem"];

  connect() {
    this.highlightClasses = "ring-1 ring-tourrise-blue".split(" ");
  }

  highlightAvailability(event) {
    const { availabilityId } = event.target.closest("a").dataset;

    this.availabilityTargets
      .filter((availability) => availability.dataset.id === availabilityId)[0]
      .classList.add(...this.highlightClasses);

    event.preventDefault();
  }

  highlightEvents(event) {
    const { id } = event.target.closest("div").dataset;

    if (id !== undefined) {
      this.eventItemTargets.forEach((eventItem) => {
        if (eventItem.dataset.availabilityId === id) {
          eventItem.classList.add(...this.highlightClasses);
        }
      });
    }

    event.preventDefault();
  }

  dropHighlight(event) {
    const { availabilityId } = event.target.closest("a").dataset;

    this.availabilityTargets
      .filter((availability) => availability.dataset.id === availabilityId)[0]
      .classList.remove(...this.highlightClasses);

    event.preventDefault();
  }

  dropEventItem(event) {
    const { id } = event.target.closest("div").dataset;

    if (id !== undefined) {
      this.eventItemTargets.forEach((eventItem) => {
        if (eventItem.dataset.availabilityId === id) {
          eventItem.classList.remove(...this.highlightClasses);
        }
      });
    }

    event.preventDefault();
  }
}
