export default (bookingRoomAssignmentId, response) => {
  const event = new CustomEvent("room-chosen", {
    detail: {
      focusedAssignmentId: bookingRoomAssignmentId,
      response,
    },
    bubbles: true,
    cancelable: true,
    composed: false,
  });

  window.dispatchEvent(event);
};
