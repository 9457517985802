import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class TransferAutocomplete extends Controller {
  static targets = [
    "flights",
    "dropoffTemplate",
    "pickupTemplate",
    "transferOption",
    "dropoff",
    "pickup",
  ];

  handleChange(event) {
    this.flightsTarget.innerHTML = "";
    this.updateFlight(event.target.value, this.flightsTarget);
  }

  static getTwoDigits(value) {
    return value < 10 ? `0${value}` : value;
  }

  static formatDate(date) {
    const day = TransferAutocomplete.getTwoDigits(date.getDate());
    const month = TransferAutocomplete.getTwoDigits(date.getMonth() + 1); // add 1 since getMonth returns 0-11 for the months
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
  }

  static formatTime(date) {
    const hours = TransferAutocomplete.getTwoDigits(date.getUTCHours());
    const mins = TransferAutocomplete.getTwoDigits(date.getUTCMinutes());

    return `${hours}:${mins}`;
  }

  static updateTransferFields(attributes) {
    const startsOnInput = document.querySelector(".js-InputElement--starts_on");
    const startTimeInput = document.querySelector(
      ".js-InputElement--start_time"
    );
    const pickupLocation = document.querySelector(
      ".js-InputElement--pickup_location"
    );
    const dropoffLocation = document.querySelector(
      ".js-InputElement--dropoff_location"
    );
    const reference = document.querySelector(".js-InputElement--reference");

    startsOnInput.value = attributes.startson;
    startTimeInput.value = attributes.starttime;
    pickupLocation.value = attributes.pickuplocation || "";
    dropoffLocation.value = attributes.dropofflocation || "";
    reference.value = attributes.reference;
  }

  static arrivalAsOption(attributes) {
    const arrivalAt = new Date(attributes.arrival_at);

    return {
      reference: attributes.display_flight,
      pickupLocation: attributes.arrival_airport,
      dropoffLocation: "",
      startTime: TransferAutocomplete.formatTime(arrivalAt),
      startsOn: TransferAutocomplete.formatDate(arrivalAt),
    };
  }

  static departureAsOption(attributes) {
    const departureAt = new Date(attributes.departure_at);

    return {
      reference: attributes.display_flight,
      pickupLocation: "",
      dropoffLocation: attributes.departure_airport,
      startTime: TransferAutocomplete.formatTime(departureAt),
      startsOn: TransferAutocomplete.formatDate(departureAt),
    };
  }

  handleClick(event) {
    if (event.target.parentElement.parentElement === this.dropoffTarget) {
      TransferAutocomplete.updateTransferFields({
        ...this.dropoffTarget.dataset,
      });
    } else if (event.target.parentElement.parentElement === this.pickupTarget) {
      TransferAutocomplete.updateTransferFields({
        ...this.pickupTarget.dataset,
      });
    }
  }

  renderOption(option) {
    const element = document.createElement("div");
    let templateTarget;
    if (option.dropoffLocation === "") {
      element.setAttribute(`data-transfer-autocomplete-target`, "pickup");
      templateTarget = this.pickupTemplateTarget;
    } else {
      element.setAttribute(`data-transfer-autocomplete-target`, "dropoff");
      templateTarget = this.dropoffTemplateTarget;
    }

    Object.keys(option).forEach((key) => {
      element.setAttribute(`data-${key}`, option[key]);
    });

    const newNode = templateTarget.content.firstElementChild.cloneNode(true);
    element.appendChild(newNode);

    Object.keys(option).forEach((key) => {
      const span = element.querySelector(`.js-Transfer-${key}`);

      if (option[key].length > 1) {
        span.innerHTML += option[key];
      } else {
        span.remove();
      }
    });

    return element;
  }

  renderOptions(attributes) {
    const options = [
      TransferAutocomplete.departureAsOption(attributes),
      TransferAutocomplete.arrivalAsOption(attributes),
    ];

    return options.map((option) => this.renderOption(option));
  }

  async updateFlight(id, flightsEl) {
    try {
      const response = await axios.get(`/api/flights/${id}`);
      const json = await response.data;
      const { attributes } = json.data;
      this.renderOptions(attributes).forEach((option) =>
        flightsEl.appendChild(option)
      );
    } catch (err) {
      Honeybadger.notify(`Error to fetch the ${id} flight`, err);
    }
  }
}
