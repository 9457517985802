import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["editSecondaryState", "selectField"];

  editState(event) {
    this.editSecondaryStateTarget.classList.add("hidden");
    this.selectFieldTarget.classList.remove("hidden");

    event.preventDefault();
  }

  saveState() {
    setTimeout(() => {
      this.selectFieldTarget.classList.add("hidden");
      this.editSecondaryStateTarget.classList.remove("hidden");
    }, 500);
  }

  closeSelect(event) {
    this.editSecondaryStateTarget.classList.remove("hidden");
    this.selectFieldTarget.classList.add("hidden");

    event.preventDefault();
  }
}
