import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tr", "template", "newRow", "placeholder"];

  addItemEvent(event) {
    const addItemEvent = new CustomEvent("add-item-event", {
      bubbles: true,
      cancelable: true,
      composed: false,
    });

    this.element.dispatchEvent(addItemEvent);

    event.preventDefault();
  }

  addItem() {
    if (this.hasTemplateTarget) {
      const node =
        this.templateTarget.content.firstElementChild.cloneNode(true);

      if (this.hasTrTarget) {
        this.trTarget.parentElement.insertBefore(node, this.trTarget);
      } else if (this.hasPlaceholderTarget) {
        this.placeholderTarget.parentElement.appendChild(node);
        this.placeholderTarget.classList.add("hidden");
      }
    }
  }

  abortAddition(event) {
    this.newRowTarget.remove();
    if (!this.hasTrTarget) this.placeholderTarget.classList.remove("hidden");

    event.preventDefault();
  }
}
