import { Controller } from "@hotwired/stimulus";
import { create, roomChosen } from "frontoffice/services/bookingRoomService";

export default class RoomController extends Controller {
  static targets = ["room"];

  static values = {
    bookingRoomAssignmentId: Number,
    roomId: Number,
    bookingScrambledId: String,
  };

  select(event) {
    event.preventDefault();

    const success = (response) => {
      roomChosen(this.bookingRoomAssignmentIdValue, response);
    };

    const error = () => {};

    const data = {
      resource_sku_id: this.roomIdValue,
      booking_scrambled_id: this.bookingScrambledIdValue,
      booking_room_assignment_id: this.bookingRoomAssignmentIdValue,
    };

    create({
      data,
      success,
      error,
    });
  }
}
