import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["bookedPackageTemplate", "bookedPackagesSection"];

  updateBookedPositions(event) {
    const node =
      this.bookedPackageTemplateTarget.content.firstElementChild.cloneNode(
        true
      );

    node.dataset.bookingResourceSku = JSON.stringify(event.detail.data);

    this.bookedPackagesSectionTarget.appendChild(node);

    if (node.scrollIntoView) {
      this.element.scrollIntoView({
        behavior: "smooth",
      });
    } else {
      window.scrollTo(node);
    }
  }
}
