import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["show", "info", "actions"];

  show(event) {
    event.preventDefault();

    this.infoTarget.classList.remove("hidden");
    this.actionsTarget.classList.remove("hidden");
    this.showTarget.classList.add("hidden");
  }

  hide(event) {
    event.preventDefault();

    this.infoTarget.classList.add("hidden");
    this.actionsTarget.classList.add("hidden");
    this.showTarget.classList.remove("hidden");
  }
}
