import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "dropdown"];

  connect() {
    this.activeClasses = "opacity-100 scale-100 ease-in duration-75".split(" ");
    this.inactiveClasses =
      "opacity-0 scale-95 ease-out duration-100 pointer-events-none".split(" ");
  }

  toggle(event) {
    event.preventDefault();

    if (this.buttonTarget.getAttribute("aria-expanded") === "false") {
      this.show();
    } else {
      this.hide(null);
    }
  }

  show() {
    this.buttonTarget.setAttribute("aria-expanded", "true");
    this.buttonTarget.focus();
    this.dropdownTarget.classList.remove(...this.inactiveClasses);
    this.dropdownTarget.classList.add(...this.activeClasses);
  }

  hide(event) {
    if (
      event &&
      ((this.dropdownTarget.contains(event.target) &&
        event.type !== "mouseleave") ||
        this.buttonTarget.contains(event.target))
    ) {
      return;
    }

    this.buttonTarget.setAttribute("aria-expanded", "false");
    this.buttonTarget.blur();
    this.dropdownTarget.classList.add(...this.inactiveClasses);
    this.dropdownTarget.classList.remove(...this.activeClasses);
  }
}
