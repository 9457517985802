import Flatpickr from "stimulus-flatpickr";

// import the translation files and create a translation mapping
import { French } from "flatpickr/dist/l10n/fr";
import { english } from "flatpickr/dist/l10n/default";
import { German } from "flatpickr/dist/l10n/de";

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  static classes = ["hover", "selected"];

  locales = {
    fr: French,
    en: english,
    de: German,
  };

  connect() {
    this.config = {
      locale: this.locale,
      altInput: true,
      showMonths: 2,
    };

    super.connect();
  }

  change(selectedDates, dateStr, instance) {
    this.dispatch("change", {
      detail: {
        selectedDates,
        dateStr,
        instance,
      },
    });
  }

  get locale() {
    if (this.locales && this.data.has("locale")) {
      return this.locales[this.data.get("locale")];
    }
    return "";
  }

  dispatch(
    eventName,
    {
      target = this.element,
      detail = {},
      prefix = this.identifier,
      bubbles = true,
      cancelable = true,
    } = {}
  ) {
    const type = prefix ? `${prefix}:${eventName}` : eventName;
    const event = new CustomEvent(type, { detail, bubbles, cancelable });
    target.dispatchEvent(event);
    return event;
  }
}
