import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["copyButton", "tooltip"];

  static values = {
    url: String,
    success: String,
  };

  connect() {
    this.rotateClasses = "transform rotate-12".split(" ");
  }

  copy(event) {
    event.preventDefault();

    // Create a temporary input element to hold the URL
    const tempInput = document.createElement("input");
    tempInput.value = this.urlValue;
    document.body.appendChild(tempInput);

    // Select and copy the URL
    tempInput.select();
    document.execCommand("copy");

    // Remove the temporary input element
    document.body.removeChild(tempInput);

    if (this.hasCopyButtonTarget && this.hasTooltipTarget) {
      this.tooltipTarget.firstElementChild.innerHTML = this.successValue;
      this.copyButtonTarget.classList.add(...this.rotateClasses);
      setTimeout(() => {
        this.copyButtonTarget.classList.remove(...this.rotateClasses);
      }, 500);
    }
  }
}
