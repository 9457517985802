import Rails from "@rails/ujs";

import currentLocale from "shared/currentLocale";

export default ({
  id,
  startsOn,
  endsOn,
  quantity,
  entireDateRange,
  success,
  error,
}) => {
  const flag = !!entireDateRange;
  const params = `starts_on=${startsOn}&ends_on=${endsOn}&entire_date_range=${flag}&quantity=${quantity}`;

  Rails.ajax({
    type: "GET",
    dataType: "json",
    url: `/${currentLocale()}/frontoffice/api/resource_skus/${id}/quantity_available?${params}`,
    success,
    error,
  });
};
