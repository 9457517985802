/* eslint-disable camelcase */
import { Controller } from "@hotwired/stimulus";

import { remove } from "frontoffice/services/bookingResourceSkusService";

export default class extends Controller {
  static targets = [
    "item",
    "image",
    "name",
    "dateRange",
    "price",
    "removeButton",
  ];

  initialize() {
    if (this.element.dataset.bookingResourceSku) {
      this.bookingResourceSku = JSON.parse(
        this.element.dataset.bookingResourceSku
      );
      this.element.dataset.id = this.bookingResourceSku.attributes.id;
      this.updateDetails();
    }
  }

  updateDetails() {
    const { id, teaser_image, name, quantity, description, localized_price } =
      this.bookingResourceSku.attributes;

    this.quantity = quantity;

    const quantityName = `${quantity}x ${name.split(" - ")[1]}`;

    this.nameTarget.innerHTML = quantityName;
    this.dateRangeTarget.innerHTML = description;
    this.priceTarget.innerHTML = localized_price;
    this.removeButtonTarget.dataset.id = id;

    if (teaser_image) {
      this.imageTarget.src = teaser_image;
    }
  }

  updateBookingTotalPrice() {
    const bookingResourceSkusChanged = new CustomEvent(
      "booking-resource-skus-changed",
      {
        bubbles: true,
        cancelable: true,
        composed: false,
      }
    );

    this.element.dispatchEvent(bookingResourceSkusChanged);
  }

  remove(event) {
    const { id } = event.target.dataset;

    const success = () => {
      this.updateBookingTotalPrice();
      this.itemTarget.remove();

      const bookingResourceSkuRemoved = new CustomEvent("item-removed", {
        detail: {
          quantity: this.quantity,
        }
      });

      window.dispatchEvent(bookingResourceSkuRemoved);
    };

    const error = () => {};

    remove({
      id,
      success,
      error,
    });
  }
}
