import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["autoClose"];

  connect() {
    this.leavingClasses = "transition ease-in duration-100 opacity-0".split(
      " "
    );

    if (this.hasAutoCloseTarget) {
      setTimeout(() => this.close(), 5000);
    }
  }

  close() {
    if (this.element) {
      // add the animation class to make element leave from dom
      this.element.classList.add(...this.leavingClasses);
      setTimeout(() => this.element.remove(), 1000); // remove from dom after 1 second
    }
  }
}
