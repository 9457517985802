/* eslint-disable camelcase */
import { Controller } from "@hotwired/stimulus";

import { remove } from "frontoffice/services/bookingResourceSkusService";

export default class extends Controller {
  static targets = ["image", "name", "description", "price", "height"];

  initialize() {
    if (this.element.dataset.bookingResourceSku) {
      this.bookingResourceSku = JSON.parse(
        this.element.dataset.bookingResourceSku
      );
      this.element.dataset.id = this.bookingResourceSku.attributes.id;
      this.updateDetails();
    }
  }

  updateBookingTotalPrice() {
    const bookingResourceSkusChanged = new CustomEvent("booking-resource-skus-changed", {
      bubbles: true,
      cancelable: true,
      composed: false,
    });

    this.element.dispatchEvent(bookingResourceSkusChanged);
  }

  updateDetails() {
    const { teaser_image, name, description, localized_price, height } =
      this.bookingResourceSku.attributes;

    this.nameTarget.innerHTML = name;
    this.descriptionTarget.innerHTML = description;
    this.priceTarget.innerHTML = localized_price;
    this.heightTarget.innerHTML = height;

    if (teaser_image) {
      this.imageTarget.src = teaser_image;
    }
  }

  remove() {
    const { id } = this.element.dataset;

    const bookingResourceSkuRemoved = new CustomEvent(
      "booking-resource-sku-removed",
      {
        detail: {
          element: this.element,
        },
        bubbles: true,
        cancelable: true,
        composed: false,
      }
    );

    const success = () => {
      this.updateBookingTotalPrice();
      this.element.remove();
      window.dispatchEvent(bookingResourceSkuRemoved);
    };

    const error = (response) => console.log(response);

    remove({
      id,
      success,
      error,
    });
  }
}
