import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "employeesButton",
    "productsButton",
    "newEmployee",
    "newProduct",
    "newEmployeeModal",
    "newProductModal",
    "employees",
    "products",
  ];

  connect() {
    this.activeColor = "bg-tourrise-aqua opacity-100".split(" ");

    if (window.location.href.split("#")[1] === "products") {
      this.activateProducts();
    } else {
      this.activateEmployees();
    }
  }

  activateEmployees() {
    this.employeesButtonTarget.classList.add(...this.activeColor);
    this.productsButtonTarget.classList.remove(...this.activeColor);

    this.newEmployeeTarget.classList.remove("hidden");
    this.newProductTarget.classList.add("hidden");

    this.employeesTarget.classList.remove("hidden");
    this.productsTarget.classList.add("hidden");

    if (this.hasNewEmployeeModalTarget) this.newEmployeeModalTarget.remove();
  }

  activateProducts() {
    this.employeesButtonTarget.classList.remove(...this.activeColor);
    this.productsButtonTarget.classList.add(...this.activeColor);

    this.newEmployeeTarget.classList.add("hidden");
    this.newProductTarget.classList.remove("hidden");

    this.employeesTarget.classList.add("hidden");
    this.productsTarget.classList.remove("hidden");

    if (this.hasNewProductModalTarget) this.newProductModalTarget.remove();
  }
}
