import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["alert"];

  static values = {
    participants: Array,
  };

  checkParticipants(event) {
    if (this.participantsValue.includes(event.detail.name)) {
      this.alertTarget.classList.remove("hidden");
    } else {
      this.alertTarget.classList.add("hidden");
    }
    this.participantsValue = [...this.participantsValue, event.detail.name];
  }

  closeAlert(event) {
    this.alertTarget.classList.add("hidden");

    event.preventDefault();
  }

  removeParticipant(event) {
    const index = this.participantsValue.indexOf(event.detail.name);
    const arr = this.participantsValue;

    if (index !== -1) arr.splice(index, 1);

    this.participantsValue = arr;
  }
}
