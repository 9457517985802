import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "selectAll"];

  selectAll() {
    this.checkboxTargets.forEach((checkbox) => {
      const current = checkbox;
      current.checked = true;
    });
  }

  deselectAll() {
    this.checkboxTargets.forEach((checkbox) => {
      const current = checkbox;
      current.checked = false;
    });
  }

  handleChange(event) {
    if (event.target.checked) {
      this.selectAll();
    } else {
      this.deselectAll();
    }
  }

  checkboxChanged() {
    let allChecked = true;
    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.checked === false) allChecked = false;
    });
    this.selectAllTarget.checked = allChecked;
  }
}
