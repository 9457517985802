import { Controller } from "@hotwired/stimulus";

import { create } from "frontoffice/services/bookingResourceSkusService";
import {
  fetchAvailable,
  fetchPrice,
} from "frontoffice/services/resourceSkusService";

export default class extends Controller {
  static targets = ["price", "resourceSkuSelect", "loadingOverlay", "height"];

  static values = {
    price: String,
    resourceSkus: Array,
    selectedResourceSkuId: Number,
    selectedDates: Array,
  };

  static observer;

  connect() {
    this.priceTarget.innerHTML = this.priceValue;

    this.startObservingVisiblity();
  }

  disconnect() {
    this.loadingValue = false;

    this.stopObservingVisibility();
  }

  initialize() {
    this.quantity = 1;
    [this.startsOn, this.endsOn] = this.selectedDatesValue;
    this.resourceId = this.element.dataset.id;
  }

  priceValueChanged(value) {
    this.priceTarget.innerHTML = value;
  }

  resourceSkusValueChanged(value) {
    if (value.length === 0) {
      this.disable();

      return;
    }

    const [firstResourceSku] = value;

    [this.selectedResourceSkuIdValue] = firstResourceSku;

    this.resourceSkuSelectTarget.innerHTML = value
      .map((resourceSku) => {
        return `<option value="${resourceSku[0]}">${resourceSku[1]}</option>`;
      })
      .join("\n");
  }

  selectedDatesValueChanged(value) {
    [this.startsOn, this.endsOn] = value;

    this.reset();
  }

  selectedResourceSkuIdValueChanged() {
    this.fetchPrice();
  }

  selectDateRange(event) {
    const {
      detail: { selectedDates },
    } = event;

    if (selectedDates.length === 2) {
      this.selectedDatesValue = [
        selectedDates[0].toLocaleDateString("se"),
        selectedDates[1].toLocaleDateString("se"),
      ];
    }
  }

  selectResourceSku(event) {
    this.selectedResourceSkuIdValue = event.target.value;
  }

  updatePrice(price) {
    this.priceValue = price;
  }

  reset() {
    this.fetchAvailableResourceSkus();
    this.fetchPrice();
  }

  fetchPrice() {
    this.showLoadingOverlay(true);

    const success = (response) => {
      this.updatePrice(response.price);
    };
    const error = () => {
      this.priceValue = "Not available";
    };

    fetchPrice({
      id: this.selectedResourceSkuIdValue,
      startsOn: this.startsOn,
      endsOn: this.endsOn,
      quantity: this.quantity,
      success,
      error,
    });

    this.showLoadingOverlay(false);
  }

  fetchAvailableResourceSkus() {
    this.showLoadingOverlay(true);

    const success = (response) => {
      this.resourceSkusValue = response.map((resourceSku) => [
        resourceSku.id,
        resourceSku.name,
      ]);
    };

    const error = () => {
      this.resourceSkusValue = [];
    };

    fetchAvailable({
      resourceId: this.resourceId,
      startsOn: this.startsOn,
      endsOn: this.endsOn,
      quantity: this.quantity,
      success,
      error,
    });

    this.showLoadingOverlay(false);
  }

  book() {
    this.showLoadingOverlay(true);

    const success = (response) => {
      const bookingResourceSkuCreated = new CustomEvent(
        "booking-resource-sku-created",
        {
          detail: {
            element: this.element,
            data: response.data,
          },
          bubbles: true,
          cancelable: true,
          composed: false,
        }
      );

      window.dispatchEvent(bookingResourceSkuCreated);

      this.updateBookingTotalPrice();
    };

    const error = () => {};

    const data = {
      booking_resource_sku: {
        booking_id: this.element.dataset.bookingId,
        resource_sku_id: this.selectedResourceSkuIdValue,
        starts_on: this.selectedDatesValue[0],
        ends_on: this.selectedDatesValue[1],
        quantity: 1,
        height: this.heightTarget.value,
      },
    };

    create({
      data,
      success,
      error,
    });

    this.showLoadingOverlay(false);
  }

  showLoadingOverlay(value) {
    if (value) {
      this.loadingOverlayTarget.classList.remove("hidden");
    } else {
      this.loadingOverlayTarget.classList.add("hidden");
    }
  }

  startObservingVisiblity() {
    const targetNode = this.element;

    const config = { attributes: true };

    const callback = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "class"
        ) {
          this.reset();
        }
      });
    };

    this.observer = new MutationObserver(callback);

    this.observer.observe(targetNode, config);
  }

  stopObservingVisibility() {
    this.observer.disconnect();
  }

  updateBookingTotalPrice() {
    const bookingResourceSkusChanged = new CustomEvent("booking-resource-skus-changed", {
      bubbles: true,
      cancelable: true,
      composed: false,
    });

    this.element.dispatchEvent(bookingResourceSkusChanged);
  }

  // eslint-disable-next-line class-methods-use-this
  disable() {}
}
