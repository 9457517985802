import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "bookedRentalbikeTemplate",
    "bookedRentalbikesSection",
    "ctaSection",
  ];

  initialize() {
    this.updateCtaSectionVisibility();
  }

  updateBookedPositions(event) {
    const node =
      this.bookedRentalbikeTemplateTarget.content.firstElementChild.cloneNode(
        true
      );

    node.dataset.bookingResourceSku = JSON.stringify(event.detail.data);

    this.bookedRentalbikesSectionTarget.appendChild(node);
    this.ctaSectionTarget.classList.remove("hidden");

    if (node.scrollIntoView) {
      this.bookedRentalbikesSectionTarget.scrollIntoView({
        behavior: "smooth",
      });
    } else {
      window.scrollTo(node);
    }
  }

  positionRemoved() {
    this.updateCtaSectionVisibility();
  }

  updateCtaSectionVisibility() {
    if (this.bookedRentalbikesSectionTarget.children.length <= 0) {
      this.ctaSectionTarget.classList.add("hidden");
    } else {
      this.ctaSectionTarget.classList.remove("hidden");
    }
  }
}
