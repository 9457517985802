import { Controller } from "@hotwired/stimulus";

export default class AccommodationController extends Controller {
  static targets = ["accommodation"];

  static classes = ["show", "hide"];

  static values = {
    bookingRoomAssignmentId: Number,
    accommodationId: Number,
  };

  showByBookingRoomAssignment(event) {
    const { classList } = this.accommodationTarget;

    if (
      event.detail.focusedAssignmentId === this.bookingRoomAssignmentIdValue
    ) {
      classList.add(this.showClass);
      classList.remove(this.hideClass);
    } else {
      classList.remove(this.showClass);
      classList.add(this.hideClass);
    }
  }
}
