import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "template", "secondRefund"];

  handlePartialPayment(event) {
    if (event.target.checked) {
      this.containerTarget.insertBefore(
        this.templateTarget.content.firstElementChild.cloneNode(true),
        null
      );
    } else {
      this.secondRefundTarget.remove();
    }

    event.preventDefault();
  }

  // add method to disable same selection in reference_payment select
}
