import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "hideMobileNav",
    "mobileNav",
    "showMobileNav",
    "mobileNavBackground",
  ];

  hideMobileNav(event) {
    event.preventDefault();

    if (
      this.mobileNavTarget &&
      this.mobileNavBackgroundTarget &&
      this.hideMobileNavTarget
    ) {
      this.mobileNavTarget.classList.remove("translate-x-0");
      this.mobileNavTarget.classList.add("-translate-x-full");

      this.hideMobileNavTarget.classList.remove("opacity-100");
      this.hideMobileNavTarget.classList.add("opacity-0");

      this.mobileNavBackgroundTarget.classList.remove("opacity-100");
      this.mobileNavBackgroundTarget.classList.add("opacity-0");

      setTimeout(() => {
        this.mobileNavTarget.parentElement.classList.add("hidden");
      }, 300);
    }
  }

  showMobileNav(event) {
    event.preventDefault();

    if (
      this.mobileNavTarget &&
      this.mobileNavBackgroundTarget &&
      this.hideMobileNavTarget
    ) {
      this.mobileNavTarget.parentElement.classList.remove("hidden");

      setTimeout(() => {
        this.mobileNavTarget.classList.remove("-translate-x-full");
        this.mobileNavTarget.classList.add("translate-x-0");

        this.hideMobileNavTarget.classList.remove("opacity-0");
        this.hideMobileNavTarget.classList.add("opacity-100");

        this.mobileNavBackgroundTarget.classList.remove("opacity-0");
        this.mobileNavBackgroundTarget.classList.add("opacity-100");
      }, 100);
    }
  }
}
