import Rails from "@rails/ujs";

export default ({
  id,
  lastVersionId,
  currentUserId,
  approvableId,
  approvableType,
  approved,
  success,
  error,
}) => {
  Rails.ajax({
    type: "PUT",
    dataType: "json",
    url: `/api/flights/${id}`,
    beforeSend: (xhr, options) => {
      // Workaround: add options.data late to avoid Content-Type header to already being set in stone
      // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
      xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
      // eslint-disable-next-line no-param-reassign
      options.data = JSON.stringify({
        last_version_id: lastVersionId,
        approved_by_user_id: currentUserId,
        approvable_id: approvableId,
        approvable_type: approvableType,
        approved,
      });
      return true;
    },
    success,
    error,
  });
};
