import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "toggleActions",
    "fabContainer",
    "actions",
    "background",
    "closeButton",
  ];

  connect() {
    this.fabContainerTarget.classList.add("hidden");
  }

  toggleActions(event) {
    event.preventDefault();
    if (this.fabContainerTarget.classList.contains("hidden")) {
      this.openActions();
    } else {
      this.closeActions();
    }
  }

  openActions() {
    this.fabContainerTarget.classList.remove("hidden");
    setTimeout(() => {
      this.backgroundTarget.classList.remove("opacity-0");
      this.backgroundTarget.classList.add("opacity-100");
      this.actionsTarget.classList.remove("translate-y-full");
      this.actionsTarget.classList.add("translate-y-0");
      this.toggleActionsTarget.classList.add("hidden");
      this.closeButtonTarget.classList.remove("opacity-0");
      this.closeButtonTarget.classList.add("opacity-100");
    }, 100);
  }

  closeActions() {
    this.closeButtonTarget.classList.remove("opacity-100");
    this.closeButtonTarget.classList.add("opacity-0");
    this.actionsTarget.classList.add("translate-y-full");
    this.actionsTarget.classList.remove("translate-y-0");
    this.backgroundTarget.classList.add("opacity-0");
    this.backgroundTarget.classList.remove("opacity-100");
    this.toggleActionsTarget.classList.remove("hidden");
    setTimeout(() => {
      this.fabContainerTarget.classList.add("hidden");
    }, 300);
  }
}
