import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "galleryTop",
    "galleryThumbs",
    "thumbnail",
    "nextButton",
    "prevButton",
  ];

  static values = {
    index: Number,
    images: Number,
  };

  connect() {
    if (window.screen.width >= 768) {
      this.visibleImages = 3;
      this.thumbsPositionMax = this.imagesValue - 3;
      this.topGalleryWidth = 360;
      this.thumbsGalleryWidth = 124;
    } else {
      this.visibleImages = 2;
      this.thumbsPositionMax = this.imagesValue - 2;
      this.topGalleryWidth = 288;
      this.thumbsGalleryWidth = 144;
    }

    if (this.imagesValue > 1) {
      if (this.indexValue === 0) {
        this.prevButtonTarget.disabled = true;
        this.nextButtonTarget.disabled = false;
      } else if (this.indexValue >= this.imagesValue - 1) {
        this.nextButtonTarget.disabled = true;
        this.prevButtonTarget.disabled = false;
      }

      this.highlightCurrentThumb();
    }
  }

  indexValueChanged() {
    this.galleryTopTarget.style = `--tw-translate-x: -${
      this.indexValue * this.topGalleryWidth
    }px;`;

    if (this.imagesValue > this.visibleImages) this.adjustThumbsGallery();

    if (this.imagesValue > 1) this.highlightCurrentThumb();
  }

  adjustThumbsGallery() {
    if (this.indexValue === 0) {
      this.galleryThumbsTarget.style = `--tw-translate-x: 0px;`;
    } else if (this.indexValue > this.thumbsPositionMax) {
      this.galleryThumbsTarget.style = `--tw-translate-x: -${
        this.thumbsPositionMax * this.thumbsGalleryWidth
      }px;`;
    } else if (this.indexValue > 0) {
      if (window.screen.width >= 768) {
        this.galleryThumbsTarget.style = `--tw-translate-x: -${
          (this.indexValue - 1) * this.thumbsGalleryWidth
        }px;`;
      } else {
        this.galleryThumbsTarget.style = `--tw-translate-x: -${
          (this.indexValue - 0.5) * this.thumbsGalleryWidth
        }px;`;
      }
    }
  }

  highlightCurrentThumb() {
    this.thumbnailTargets.forEach((thumb) => {
      if (Number(thumb.dataset.index) === this.indexValue) {
        thumb.classList.remove("opacity-50");
      } else {
        thumb.classList.add("opacity-50");
      }
    });
  }

  next(event) {
    this.prevButtonTarget.disabled = false;
    this.indexValue += 1;

    if (this.indexValue >= this.imagesValue - 1)
      this.nextButtonTarget.disabled = true;

    event.preventDefault();
  }

  previous(event) {
    this.nextButtonTarget.disabled = false;
    this.indexValue -= 1;

    if (this.indexValue === 0) this.prevButtonTarget.disabled = true;

    event.preventDefault();
  }

  showImage(event) {
    if (this.imagesValue > 1) {
      this.indexValue = event.target.dataset.index;

      if (this.indexValue === 0) {
        this.prevButtonTarget.disabled = true;
        this.nextButtonTarget.disabled = false;
      } else if (this.indexValue < this.imagesValue - 1) {
        this.prevButtonTarget.disabled = false;
        this.nextButtonTarget.disabled = false;
      } else if (this.indexValue >= this.imagesValue - 1) {
        this.prevButtonTarget.disabled = false;
        this.nextButtonTarget.disabled = true;
      }
    }
  }

  resizeGallery() {
    this.connect();

    this.galleryTopTarget.style = `--tw-translate-x: -${
      this.indexValue * this.topGalleryWidth
    }px;`;

    if (this.imagesValue > this.visibleImages) this.adjustThumbsGallery();
  }
}
