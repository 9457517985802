import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    successUrl: String,
  };

  connect() {
    this.pollPaymentStatus(0);
  }

  pollPaymentStatus(iteration) {
    if (iteration < 40) {
      fetch("./poll_status")
        .then((response) => response.json())
        .then((data) => {
          if (
            data.payment_status === "paid" ||
            data.payment_status === "pending_sepa"
          ) {
            window.location.href = this.successUrlValue;
          } else {
            setTimeout(() => this.pollPaymentStatus(iteration + 1), 5000);
          }
        });
    } else {
      window.location.href = "./failed";
    }
  }
}
