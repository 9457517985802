import Rails from "@rails/ujs";

export default ({ carrier, flightNumber, onDate, success, error }) => {
  const params = `carrier=${carrier}&flight_number=${flightNumber}&on_date=${onDate}`;

  Rails.ajax({
    type: "GET",
    dataType: "json",
    url: `/api/flights/search?${params}`,
    success,
    error,
  });
};
